<!--
 * @Author: 艾斯
 * @Description: 组件演示
 * @Date: 2022-07-02 11:34:10
 * @LastEditTime: 2022-08-03 11:32:08
 * @FilePath: /sksaas-web/src/pages/Demo/Index.vue
-->
<!--  -->
<template>
  <div class="demo">
    <h2>组件演示</h2>
    <el-button type="primary" size="default" @click="openDialog('dialog')">通用弹框</el-button>
    <el-button type="primary" size="default" @click="openDialog('drawer')">抽屉弹框</el-button>
    <el-button type="primary" size="default" @click="openFormDialog('drawer')">内置表单</el-button>

    <!-- 通用弹窗组件 -->
    <m-dialog :dialogVisible.sync="dialogVisible" :type="dialogType" :saveLoding.sync="saveLoding" @close="dialogClose" @saveSubmit="saveSubmit">
      <div class="text" v-html="text"></div>
    </m-dialog>

    <m-dialog :dialogVisible.sync="formDialogVisible" :type="dialogType" :saveLoding.sync="saveLoding" @close="dialogClose" @saveSubmit="saveSubmit">
      <m-form ref="myForm" :formItemOptions="formItemOptions" :rules="rules"></m-form>
    </m-dialog>
    <!--     
      通用弹窗组件
      属性            类型        描述
      dialogVisible  Boolean     是否显示,默认false
      type           String      弹窗类型[dialog | drawer],默认dialog
      width          String      宽度，默认800px ,单位[px | %]
      height         String      高度，默认500px,,单位[px | %],drawer类型无法设置
      title          String      标题
      showFooter     Boolean     是否显示底部按钮，默认 true
      showLoading    Boolean     底部确定按钮是否显示loading，默认 false
      saveLoding     Boolean     确定按钮是否在加载中，showLoading为true时候必传

      close          function    关闭弹窗事件
      saveSubmit     function    点击确定按钮事件
     -->
    <br />
    <h3>通用表格</h3>
    <m-tabel
      :formList="formOptions"
      :tableHead="tableHead"
      :tableData="tableData"
      :showSummary="showSummary"
      :isShowPage="isShowPage"
      :pageSize="pageSize"
      :total="total"
      :loading="false"
      :currentPage="currentPage"
      @submitForm="searchSubmit"
      @pageChange="pageChange"
    >
      <template #slot="scope">
        <template v-if="scope.label === '品牌LOGO'">
          <img :src="scope.row.logoUrl" alt="" height="30" />
        </template>
        <template v-if="scope.label === '商品数量'">
          <el-tag type="success">{{ scope.row.num }}</el-tag>
        </template>
        <template v-if="scope.label === '操作'">
          <el-button type="text">
            移除
          </el-button>
        </template>
      </template>
    </m-tabel>

    <!--     
      通用表格组件
      属性            类型        是否必传      描述
      tableHead      Array       是          表头配置信息，具体看tableHead说明
      tableData      Array       是          表格内容
      formList       Array       否          配置检索表单，不传表示不显示检索表单，具体看formList说明
      loading        Boolean     是          表格加载中，默认false
      showSummary    Boolean     否          显示表尾合计,默认false
      maxHeight      String      否          表格最大高度，默认500px
      isShowPage     Boolean     否          显示分页,默认true,为true的时候，以下三项必填
      pageSize       Number      否          每页条数，默认10
      total          Number      否          总条数
      currentPage    Number      否          当前页码

      submitForm     function    否          检索表单提交事件，返回值为检索表单内容对象
      pageChange     function    否          分页改变事件，返回新的页码值

      tableHead说明
      属性         类型            是否必传      描述
      prop        String          是           对应tableData的字段名     
      label       String          是           列的标题    
      width       Number          否           列的宽度，默认自适应，不需要带单位    
      fixed       String|Boolean  否           列是否冻结[true|'left','right']  
      isSlot      Boolean         否           自定义列单元格内容，具体用法看示例 

      formList说明
      属性           类型            是否必传      描述
      model         String          是           表单项字段名     
      placeholder   String          否           表单项描述    
      type          String          否           表单项类型[select下拉框|cascader级联选择|datePicker日期选择],不传为input  
      options       Array           否           type为[select|cascader]的时候必传，select示例值 [{label:'选项内容',value:'选项值'}]  cascader示例值 [{label:'选项内容',value:'选项值',children:[{label:'子选项内容',value:'子选项值'}]}]
      multiple      Boolean         否           是否多选，type为select的时候可选，默认false
      props         Object          否           cascader选项配置，type为cascader的时候可选，具体看props说明
      
      props说明
      属性           类型            是否必传      描述
      multiple      Boolean         否           是否多选     
      label         String          否           自定义options选项值的label字段名
      value         String          否           自定义options选项值的value字段名
      children      String          否           自定义options选项值的children字段名
     -->

    <br />
    <h3>通用表单</h3>
    <m-form :formItemOptions="formItemOptions" :rules="rules"></m-form>
    <!--
     通用表单配置
     属性                 类型        是否必传        描述
     formItemOptions     Array       是             表单项配置，具体看formItemOptions说明
     rules               Array       否             验证规则配置，具体看rules说明,用法看示例
     labelWidth          String      否             表单域标签的宽度, 默认值120px
     width               string      否             表单宽度，默认值500px
     
     formItemOptions说明
     属性           类型                  是否必传      描述
     model         String                是           表单项字段名     
     label         String                是           表单项标题    
     value         String|Number|Array   是           表单项默认value值 
     placeholder   String                否           表单项描述    
     type          String                否           表单项类型[select下拉框|cascader级联选择|datePicker日期选择|checkbox复选框|radio单选框|desc文本域],不传为input  
     options       Array                 否           type为[select|cascader|checkbox|radio]的时候必传，非cascader示例值 [{label:'选项内容',value:'选项值'}]  cascader示例值 [{label:'选项内容',value:'选项值',children:[{label:'子选项内容',value:'子选项值'}]}]
     multiple      Boolean               否           是否多选，type为select的时候可选，默认false
     props         Object                否           cascader选项配置，type为cascader的时候可选，用法和通用表格组件的相关用法一致
     prop          String                否           验证规则配置的key值，不传表示不需要验证


     rules说明
     属性           类型                    描述
     required      Boolean                 是否是必填项,默认false     
     message       String                  验证不通过，文字提示内容    
     trigger       String                  验证时机，[blur失去焦点|change值改变] 
     min           Number                  内容最小长度
     max           Number                  内容最大长度
     type          String                  当验证项为多选时候必填，[array]
     -->
  </div>
</template>

<script>
const cascader = [
  {
    value: 'zhinan',
    label: '指南',
    children: [
      {
        value: 'shejiyuanze',
        label: '设计原则',
        children: [
          {
            value: 'yizhi',
            label: '一致'
          },
          {
            value: 'fankui',
            label: '反馈'
          },
          {
            value: 'xiaolv',
            label: '效率'
          },
          {
            value: 'kekong',
            label: '可控'
          }
        ]
      },
      {
        value: 'daohang',
        label: '导航',
        children: [
          {
            value: 'cexiangdaohang',
            label: '侧向导航'
          },
          {
            value: 'dingbudaohang',
            label: '顶部导航'
          }
        ]
      }
    ]
  },
  {
    value: 'zujian',
    label: '组件',
    children: [
      {
        value: 'basic',
        label: 'Basic',
        children: [
          {
            value: 'layout',
            label: 'Layout 布局'
          },
          {
            value: 'color',
            label: 'Color 色彩'
          },
          {
            value: 'typography',
            label: 'Typography 字体'
          },
          {
            value: 'icon',
            label: 'Icon 图标'
          },
          {
            value: 'button',
            label: 'Button 按钮'
          }
        ]
      },
      {
        value: 'form',
        label: 'Form',
        children: [
          {
            value: 'radio',
            label: 'Radio 单选框'
          },
          {
            value: 'checkbox',
            label: 'Checkbox 多选框'
          },
          {
            value: 'input',
            label: 'Input 输入框'
          },
          {
            value: 'input-number',
            label: 'InputNumber 计数器'
          },
          {
            value: 'select',
            label: 'Select 选择器'
          },
          {
            value: 'cascader',
            label: 'Cascader 级联选择器'
          },
          {
            value: 'switch',
            label: 'Switch 开关'
          },
          {
            value: 'slider',
            label: 'Slider 滑块'
          },
          {
            value: 'time-picker',
            label: 'TimePicker 时间选择器'
          },
          {
            value: 'date-picker',
            label: 'DatePicker 日期选择器'
          },
          {
            value: 'datetime-picker',
            label: 'DateTimePicker 日期时间选择器'
          },
          {
            value: 'upload',
            label: 'Upload 上传'
          },
          {
            value: 'rate',
            label: 'Rate 评分'
          },
          {
            value: 'form',
            label: 'Form 表单'
          }
        ]
      },
      {
        value: 'data',
        label: 'Data',
        children: [
          {
            value: 'table',
            label: 'Table 表格'
          },
          {
            value: 'tag',
            label: 'Tag 标签'
          },
          {
            value: 'progress',
            label: 'Progress 进度条'
          },
          {
            value: 'tree',
            label: 'Tree 树形控件'
          },
          {
            value: 'pagination',
            label: 'Pagination 分页'
          },
          {
            value: 'badge',
            label: 'Badge 标记'
          }
        ]
      },
      {
        value: 'notice',
        label: 'Notice',
        children: [
          {
            value: 'alert',
            label: 'Alert 警告'
          },
          {
            value: 'loading',
            label: 'Loading 加载'
          },
          {
            value: 'message',
            label: 'Message 消息提示'
          },
          {
            value: 'message-box',
            label: 'MessageBox 弹框'
          },
          {
            value: 'notification',
            label: 'Notification 通知'
          }
        ]
      },
      {
        value: 'navigation',
        label: 'Navigation',
        children: [
          {
            value: 'menu',
            label: 'NavMenu 导航菜单'
          },
          {
            value: 'tabs',
            label: 'Tabs 标签页'
          },
          {
            value: 'breadcrumb',
            label: 'Breadcrumb 面包屑'
          },
          {
            value: 'dropdown',
            label: 'Dropdown 下拉菜单'
          },
          {
            value: 'steps',
            label: 'Steps 步骤条'
          }
        ]
      },
      {
        value: 'others',
        label: 'Others',
        children: [
          {
            value: 'dialog',
            label: 'Dialog 对话框'
          },
          {
            value: 'tooltip',
            label: 'Tooltip 文字提示'
          },
          {
            value: 'popover',
            label: 'Popover 弹出框'
          },
          {
            value: 'card',
            label: 'Card 卡片'
          },
          {
            value: 'carousel',
            label: 'Carousel 走马灯'
          },
          {
            value: 'collapse',
            label: 'Collapse 折叠面板'
          }
        ]
      }
    ]
  },
  {
    value: 'ziyuan',
    label: '资源',
    children: [
      {
        value: 'axure',
        label: 'Axure Components'
      },
      {
        value: 'sketch',
        label: 'Sketch Templates'
      },
      {
        value: 'jiaohu',
        label: '组件交互文档'
      }
    ]
  }
]
export default {
  data() {
    return {
      text: '',
      dialogVisible: false,
      formDialogVisible: false,
      dialogType: 'dialog',
      saveLoding: false,
      formOptions: [
        {
          model: 'name',
          placeholder: '员工姓名'
        },
        {
          model: 'age',
          placeholder: '员工年龄'
        },
        {
          type: 'select',
          model: 'state',
          placeholder: '请选择状态',
          options: [
            {
              label: '在职',
              value: 0
            },
            {
              label: '离职',
              value: 1
            }
          ]
        },
        {
          type: 'select',
          multiple: true,
          model: 'value',
          placeholder: '请选择多个',
          options: [
            {
              value: '选项1',
              label: '黄金糕'
            },
            {
              value: '选项2',
              label: '双皮奶'
            },
            {
              value: '选项3',
              label: '蚵仔煎'
            },
            {
              value: '选项4',
              label: '龙须面'
            },
            {
              value: '选项5',
              label: '北京烤鸭'
            }
          ]
        },
        {
          type: 'cascader',
          model: 'cascader1',
          placeholder: '级联选择',
          options: [...cascader]
        },
        {
          type: 'cascader',
          props: {
            multiple: true,
            value: 'value',
            label: 'label',
            children: 'children'
          },
          model: 'cascader2',
          placeholder: '级联选择配置',
          options: [...cascader]
        },
        {
          type: 'datePicker',
          model: 'date1',
          placeholder: '请选择日期'
        }
      ],
      tableHead: [
        { prop: 'selection', width: 50 },
        { prop: 'id', label: '品牌ID', width: 50 },
        { prop: 'logoUrl', label: '品牌LOGO', width: 70, isSlot: true },
        { prop: 'name', label: '品牌名称' },
        { prop: 'alias', label: '品牌别名', width: 100, fixed: true },
        { prop: 'desc', label: '品牌描述', width: 140 },
        { prop: 'brandGroupName', label: '品牌分组', width: 60 },
        { prop: 'num', label: '商品数量', width: 116, isSlot: true },
        { prop: 'createTime', label: '添加时间', width: 140 },
        { prop: 'status', label: '状态', width: 60 },
        { prop: '', label: '操作', width: 140, isSlot: true }
      ],
      tableData: [
        {
          id: 110,
          logoUrl: 'https://img0.baidu.com/it/u=2594623305,3732454804&fm=253&fmt=auto&app=138&f=PNG?w=513&h=309',
          name: '小米',
          alias: 'xiaomi',
          desc: '米家职能，美好生活',
          brandGroupName: '第七组',
          num: 20,
          createTime: '2019-10-10',
          status: '下架'
        },
        {
          id: 110,
          logoUrl: 'https://img0.baidu.com/it/u=2594623305,3732454804&fm=253&fmt=auto&app=138&f=PNG?w=513&h=309',
          name: '小米',
          alias: 'xiaomi',
          desc: '米家职能，美好生活',
          brandGroupName: '第七组',
          num: 20,
          createTime: '2019-10-10',
          status: '下架'
        },
        {
          id: 110,
          logoUrl: 'https://img0.baidu.com/it/u=2594623305,3732454804&fm=253&fmt=auto&app=138&f=PNG?w=513&h=309',
          name: '小米',
          alias: 'xiaomi',
          desc: '米家职能，美好生活',
          brandGroupName: '第七组',
          num: 20,
          createTime: '2019-10-10',
          status: '下架'
        }
      ],
      showSummary: true,
      isShowPage: true,
      pageSize: 10,
      total: 100,
      currentPage: 1, 
      maxHeight: '',

      // 通用表单属性
      formItemOptions: [
        {
          model: 'name', // 必填
          label: '名称', // 必填
          value: '', // 必填
          placeholder: '活动名称',
          // type: 'select', //表单项类型,不传表示input , [select|datePicker|cascader|checkbox|radio|textarea]
          prop: 'name' // 验证规则key值，不传表示不需要验证
        },
        {
          model: 'region',
          label: '活动区域',
          value: '',
          placeholder: '请选择活动区域',
          type: 'select', //select 必须配置 options
          options: [
            {
              label: '区域1',
              value: 'shanghai'
            },
            {
              label: '区域2',
              value: 'nanning'
            }
          ],
          prop: 'region'
        },
        {
          model: 'region1',
          label: '活动区域多选',
          value: [],
          placeholder: '请选择活动区域',
          type: 'select', //select 必须配置 options
          multiple: true, //是否多选，默认false
          options: [
            {
              label: '区域1',
              value: 'shanghai'
            },
            {
              label: '区域2',
              value: 'nanning'
            }
          ]
        },
        {
          model: 'date1',
          label: '活动时间',
          value: '', //绑定格式 2022-01-20
          placeholder: '请选择活动时间',
          type: 'datePicker',
          prop: 'date1'
        },
        {
          model: 'date2',
          label: '级联选择',
          value: [],
          placeholder: '',
          type: 'cascader', //必须配置 options
          options: [...cascader],
          prop: 'date2'
        },
        {
          model: 'date3',
          label: '级联选择多选',
          value: '',
          placeholder: '',
          type: 'cascader', //必须配置 options
          options: [...cascader],
          optionsProps: {
            multiple: true, //是否多选
            value: 'value', //指定选项的值为选项对象的某个属性值
            label: 'label', //指定选项标签为选项对象的某个属性值
            children: 'children' //指定选项的子选项为选项对象的某个属性值
          },
          prop: 'date3'
        },
        {
          model: 'type',
          label: '活动性质',
          value: [],
          type: 'checkbox', //必须配置 options
          options: [
            {
              label: '美食/餐厅线上活动',
              value: '1'
            },
            {
              label: '地推活动',
              value: '2'
            },
            {
              label: '线下主题活动',
              value: '3'
            },
            {
              label: '单纯品牌曝光',
              value: '4'
            }
          ],
          prop: 'type'
        },
        {
          model: 'resource',
          label: '特殊资源',
          value: '1',
          type: 'radio', //必须配置 options
          options: [
            {
              label: '线上品牌商赞助',
              value: '1'
            },
            {
              label: '线下场地免费',
              value: '2'
            }
          ],
          prop: 'resource'
        },
        {
          model: 'desc',
          label: '活动形式',
          value: '',
          placeholder: '请填写活动形式',
          type: 'textarea',
          prop: 'desc'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        date1: [{ required: true, message: '请选择日期', trigger: 'change' }],
        date2: [{ required: true, message: '请选择级联', trigger: 'change' }],
        date3: [{ type: 'array', required: true, message: '至少选一个', trigger: 'change' }],
        type: [{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }],
        resource: [{ required: true, message: '请选择活动资源', trigger: 'change' }],
        desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
      }
    }
  },
  created() {
    for (let index = 0; index < 1000; index++) {
      this.text += index + '<br>'
    }
  },
  methods: {
    openDialog(type) {
      this.dialogVisible = !this.dialogVisible
      this.dialogType = type
    },
    openFormDialog(type) {
      this.formDialogVisible = !this.formDialogVisible
      this.dialogType = type
    },
    // 搜索事件，返回表单对象
    searchSubmit(obj) {
      console.log(obj)
    },
    // 分页切换事件，返回新的页码
    pageChange(page) {
      console.log(page)
    },
    dialogClose() {
      // 弹窗关闭回调事件
      this.$refs.myForm.resetForm() // 重置表单
    },
    saveSubmit() {
      // 弹窗提交按钮事件
      this.saveLoding = false
      // 提交表单事件
      this.$refs.myForm
        .submitForm()
        .then(res => {
          console.log(res)
          this.saveLoding = false
        })
        .catch(() => {
          this.saveLoding = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
.demo {
  padding: 30px;
}
</style>
